<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek No Peserta</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-6" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpNoPeserta" class="form-control" 
                            placeholder="Ketik No Peserta" />
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchNoPeserta" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetNoPeserta">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwNoPeserta">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Kartu</h4>
                                            <p>{{dataNoPeserta.peserta.noKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>NIK</h4>
                                            <p>{{dataNoPeserta.peserta.nik||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama</h4>
                                            <p>{{dataNoPeserta.peserta.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pisa</h4>
                                            <p>{{dataNoPeserta.peserta.pisa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>sex</h4>
                                            <p>{{dataNoPeserta.peserta.sex||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglLahir</h4>
                                            <p>{{dataNoPeserta.peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglCetakKartu</h4>
                                            <p>{{dataNoPeserta.peserta.tglCetakKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTAT</h4>
                                            <p>{{dataNoPeserta.peserta.tglTAT||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTMT</h4>
                                            <p>{{dataNoPeserta.peserta.tglTMT||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>statusPeserta</h4>
                                            <p>Kode : {{dataNoPeserta.peserta.statusPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataNoPeserta.peserta.statusPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>mr</h4>
                                            <p>noMR : {{dataNoPeserta.peserta.mr.noMR||"-"}}</p>
                                            <p>noTelepon : {{dataNoPeserta.peserta.mr.noTelepon||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provUmum</h4>
                                            <p>kdProvider : {{dataNoPeserta.peserta.provUmum.kdProvider||"-"}}</p>
                                            <p>nmProvider : {{dataNoPeserta.peserta.provUmum.nmProvider||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr
                                    >  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jenisPeserta</h4>
                                            <p>Kode : {{dataNoPeserta.peserta.jenisPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataNoPeserta.peserta.jenisPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>hakKelas</h4>
                                            <p>Kode : {{dataNoPeserta.peserta.hakKelas.kode||"-"}}</p>
                                            <p>Keterangan : {{dataNoPeserta.peserta.hakKelas.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 

                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>umur</h4>
                                            <p>umurSekarang : {{dataNoPeserta.peserta.umur.umurSekarang||"-"}}</p>
                                            <p>umurSaatPelayanan : {{dataNoPeserta.peserta.umur.umurSaatPelayanan||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>informasi</h4>
                                            <p>dinsos : {{dataNoPeserta.peserta.informasi.dinsos||"-"}}</p>
                                            <p>prolanisPRB : {{dataNoPeserta.peserta.informasi.prolanisPRB||"-"}}</p> 
                                            <p>noSKTM : {{dataNoPeserta.peserta.informasi.noSKTM||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>cob</h4>
                                            <p>noAsuransi : {{dataNoPeserta.peserta.cob.noAsuransi||"-"}}</p>
                                            <p>nmAsuransi : {{dataNoPeserta.peserta.cob.nmAsuransi||"-"}}</p> 
                                            <p>tglTMT : {{dataNoPeserta.peserta.cob.tglTMT||"-"}}</p> 
                                            <p>tglTAT : {{dataNoPeserta.peserta.cob.tglTAT||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>  
                        </div>
                    </div>
                </div>
            </b-card>

            <b-card no-body class="mb-3">
                <b-card-header>
                    <h6 class="card-title font-weight-semibold">Cek NIK</h6>
                </b-card-header>
                <div class="card-body p-2">
                    <div class="row">
                    <div class="col-lg-6" id="formOptPenunjang">
                        <div class="row gx-1">
                        <div class="col-md">
                            <div class="form-group mb-0">
                            <input v-model="inpNIK" class="form-control" 
                            placeholder="Ketik NIK" />
                            </div>
                        </div>
                        <div class="col-md-auto">
                            <button @click="searchNIK" type="button" class="btn btn-labeled btn-labeled-left bg-blue-400 text-orange-800 mr-1"><b><i
                                class="icon-search4"></i></b>Cari</button>
                            <button type="button" class="btn apha-blue border-blue text-blue-800" @click="resetNIK">Reset</button>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="row pt-2" v-if="shwNIK">
                        <div class="col-lg-12">
                            <table class="table table-bordered table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>No. Kartu</h4>
                                            <p>{{dataNIK.peserta.noKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>NIK</h4>
                                            <p>{{dataNIK.peserta.nik||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>Nama</h4>
                                            <p>{{dataNIK.peserta.nama||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>pisa</h4>
                                            <p>{{dataNIK.peserta.pisa||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>sex</h4>
                                            <p>{{dataNIK.peserta.sex||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglLahir</h4>
                                            <p>{{dataNIK.peserta.tglLahir||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglCetakKartu</h4>
                                            <p>{{dataNIK.peserta.tglCetakKartu||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTAT</h4>
                                            <p>{{dataNIK.peserta.tglTAT||"-"}}</p>
                                            </div>
                                        </td>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>tglTMT</h4>
                                            <p>{{dataNIK.peserta.tglTMT||"-"}}</p>
                                            </div>
                                        </td>
                                    </tr>  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>statusPeserta</h4>
                                            <p>Kode : {{dataNIK.peserta.statusPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataNIK.peserta.statusPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>mr</h4>
                                            <p>noMR : {{dataNIK.peserta.mr.noMR||"-"}}</p>
                                            <p>noTelepon : {{dataNIK.peserta.mr.noTelepon||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>provUmum</h4>
                                            <p>kdProvider : {{dataNIK.peserta.provUmum.kdProvider||"-"}}</p>
                                            <p>nmProvider : {{dataNIK.peserta.provUmum.nmProvider||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr
                                    >  
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>jenisPeserta</h4>
                                            <p>Kode : {{dataNIK.peserta.jenisPeserta.kode||"-"}}</p>
                                            <p>Keterangan : {{dataNIK.peserta.jenisPeserta.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 
                                        
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>hakKelas</h4>
                                            <p>Kode : {{dataNIK.peserta.hakKelas.kode||"-"}}</p>
                                            <p>Keterangan : {{dataNIK.peserta.hakKelas.keterangan||"-"}}</p> 
                                            </div>
                                        </td> 

                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>umur</h4>
                                            <p>umurSekarang : {{dataNIK.peserta.umur.umurSekarang||"-"}}</p>
                                            <p>umurSaatPelayanan : {{dataNIK.peserta.umur.umurSaatPelayanan||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>informasi</h4>
                                            <p>dinsos : {{dataNIK.peserta.informasi.dinsos||"-"}}</p>
                                            <p>prolanisPRB : {{dataNIK.peserta.informasi.prolanisPRB||"-"}}</p> 
                                            <p>noSKTM : {{dataNIK.peserta.informasi.noSKTM||"-"}}</p> 
                                            </div>
                                        </td> 
                                        <td width="33%">
                                            <div class="result_tab">
                                            <h4>cob</h4>
                                            <p>noAsuransi : {{dataNIK.peserta.cob.noAsuransi||"-"}}</p>
                                            <p>nmAsuransi : {{dataNIK.peserta.cob.nmAsuransi||"-"}}</p> 
                                            <p>tglTMT : {{dataNIK.peserta.cob.tglTMT||"-"}}</p> 
                                            <p>tglTAT : {{dataNIK.peserta.cob.tglTAT||"-"}}</p> 
                                            </div>
                                        </td> 
                                    </tr>
                                </tbody>
                            </table>  
                        </div>
                    </div>
                </div>
            </b-card>
        </div>
    </div>    
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  data() {
    return {
        inpNoPeserta:"",
        shwNoPeserta: false,  
        dataNoPeserta: {},
        
        inpNIK:"",
        shwNIK: false,  
        dataNIK: {},
    }
  },
  methods: {
    searchNoPeserta(){
        if(this.inpNoPeserta){
            this.loadingOverlay = true
            let data = {
                type : "search-no-peserta",
                search : this.inpNoPeserta
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwNoPeserta = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Peserta Tidak Ditemukan'
                    })
                }
                this.dataNoPeserta = resp.response
                this.loadingOverlay = false
                this.shwNoPeserta = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Peserta Tidak Ditemukan'
                })
            })
        }
    },
    resetNoPeserta(){
        this.inpNoPeserta = null
        this.shwNoPeserta = false
        this.dataNoPeserta = {}
    },

    searchNIK(){
        if(this.inpNIK){
            this.loadingOverlay = true
            let data = {
                type : "search-nik",
                search : this.inpNIK
            }
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                let resp = res.data
                if(resp.status == "fail"){
                    this.loadingOverlay = false
                    this.shwNIK = false
                    return this.$swal({
                        icon: 'error',
                        text: 'Data Peserta Tidak Ditemukan'
                    })
                
                }
                this.dataNIK = resp.response
                this.loadingOverlay = false
                this.shwNIK = true
            }).catch(err=>{
                this.loadingOverlay = false
                return this.$swal({
                    icon: 'error',
                    text: 'Data Peserta Tidak Ditemukan'
                })
            })
        }
    },
    resetNIK(){
        this.inpNIK = null
        this.shwNIK = false
        this.dataNIK = {}
    }
  },
}
</script>